<template>
  <div v-if="portfolioId === 'v' && !searchFilter && !cachedFilter">
    <div class="d-flex flex-column py-5 my-5 justify-content-center align-items-center">
      <span class="font-weight-bold h4">
        Please make your selection again to analyse a hypothetical portfolio
      </span>

      <b-button
        class="mt-2"
        variant="primary"
        @click.prevent="$router.push({name: getRedirectSource.route})"
      >
        Go Back To {{ getRedirectSource.name }}
      </b-button>
    </div>
  </div>
  <div
    v-else-if="portfolioId !== 'v' && loading"
    class="d-flex flex-column justify-content-center align-items-center py-5 my-5"
  >
    <b-spinner variant="primary" />
  </div>
  <div
    v-else-if="portfolioId !== 'v' && portfolioId !== 'u' && products.length < 1"
    class="d-flex flex-column justify-content-center align-items-center py-5 my-5"
  >
    <p>There are no products in this portfolio.</p>
    <p>You need to search for products to add to the portfolio. You can also view all your existing portfolios.</p>
    <div class="mt-2">
      <b-button
        variant="primary"
        class="mr-1"
        @click.prevent="$router.push({name: 'products'})"
      >
        Go To Products
      </b-button>

      <b-button
        variant="primary"
        class="mr-1"
        @click.prevent="$router.push({name: 'portfolios'})"
      >
        Go To Portfolios
      </b-button>
      <b-button
        variant="warning"
        @click.prevent="$router.push({ name: 'portfolio-edit', params: { portfolioId: portfolioId } })"
      >
        Edit Portfolio
      </b-button>
    </div>
  </div>
  <div v-else>
    <loading-container v-if="loading" />
    <error-display
      v-else-if="reportError"
      @action-clicked="loadReport"
    />
    <template v-else>
      <div>
        <div class="d-flex justify-content-between mb-2">
          <span>
            {{ $auth.user ? $auth.user.name : '' }}
          </span>
          <span>
            {{ date }}
          </span>
        </div>

        <report-print-brand-header report-type="Portfolio" />

        <b-card class="mb-0">
          <template v-if="appBrand === 'srp'">
            Portfolio data report showing the latest calculated data for the selected portfolio or collection of structured products shown below.
            An up-to-date version of this report is available on the StructrPro service at
            <b-link :href="fullReportLink.href">
              {{ fullReportLink.text }}
            </b-link>
          </template>
          <template v-else-if="appBrand === 'se'">
            Portfolio data report showing the latest calculated data for the selected portfolio or collection of structured products shown below.
            An up-to-date version of this report is available on the Structured Edge Plus service at
            <b-link :href="fullReportLink.href">
              {{ fullReportLink.text }}
            </b-link>
          </template>
        </b-card>

        <b-card class="mb-0">
          <h2 class="text-center">
            <span class="align-self-center">{{ portfolioTitle }}</span>
          </h2>
        </b-card>
      </div>

      <b-row>
        <b-col>
          <b-card class="mb-0">
            <div class="d-flex align-items-center my-n1">
              <span class="mr-1">Product weighting</span>
              <v-select
                v-model="productWeightMode.selectedMode"
                :options="productWeightModeOptions"
                :reduce="mode => mode.value"
                :clearable="false"
                label="name"
                style="min-width: 220px"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col cols="6">
          <report-table-card
            title="Portfolio Summary"
            explanation="Portfolio summary and totals<span class=invisible>For live products results use the latest valuations</span>"
            table-header
            :fields="report.state_summary.header"
            :items="report.state_summary.content"
            :show-info-icon="false"
            @onShowProducts="showProductsListModal"
          />
        </b-col>

        <b-col cols="6">
          <report-table-card
            title="Performance Summary"
            explanation="Performance and returns. For live products results use the latest valuations "
            table-header
            :fields="report.state_maturity.header"
            :items="report.state_maturity.content"
            :show-info-icon="false"
            @onShowProducts="showProductsListModal"
          />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col cols="6">
          <b-card>
            <b-card-title class="d-flex flex-column flex-md-row justify-content-between align-md-items-center">
              <span>Portfolio Evolution</span>
            </b-card-title>

            <b-card-sub-title
              v-if="portfolioId !== 'u'"
              class="mb-1"
            >
              <span>
                Portfolio value compared to the underlyings basket and the calculated Structured Product index.
                Live proportion of products also shown
              </span>
            </b-card-sub-title>
            <b-card-sub-title
              v-if="portfolioId === 'u'"
              class="mb-1"
            >
              <span>
                Selectable choice of performance of benchmarks and calculated Structured Product indices
              </span>
            </b-card-sub-title>

            <b-card-text>
              <vue-apex-charts
                ref="dynamicChart"
                type="line"
                height="300"
                :options="evolutionLineChartOptions"
                :series="report.portfolioEvolutionIndexSeries"
              />
            </b-card-text>
            <div>
              <b-card-text v-if="indexWeightMode">
                Products in the Structured Produx Index are weighted by {{ indexWeightMode }}
              </b-card-text>
              <b-card-text class="d-flex justify-content-between">
                <span>
                  {{ (portfolioId === 'u' ? 'Structured Product Index' : 'Portfolio value') }}
                  volatility relative to underlying basket is {{ report.indexUnderlyingVolRatio }}
                </span>
              </b-card-text>
            </div>
          </b-card>
        </b-col>

        <b-col cols="6">
          <histogram-chart
            title="Matured Products"
            explanation="Annualised total returns for all matured products"
            :series="report.returns"
            :animate="false"
          />
        </b-col>
      </b-row>

      <div class="pagebreak" />

      <b-row class="match-height">
        <b-col cols="6">
          <ecommerce-order-chart
            explanation="Breakdown of possible and final future maturities"
            :loading="loading"
            :height="300"
            :data="report.futureMaturities"
            :animate="false"
          />
        </b-col>
        <b-col cols="6">
          <b-card title="Payoff Scatter">
            <b-card-sub-title class="pb-2">
              <span>
                Matured and live product payoff versus maturity time
              </span>
            </b-card-sub-title>
            <vue-apex-charts
              type="scatter"
              height="300"
              :options="payoffScatterOptions"
              :series="report.payoffScatter.data"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col cols="6">
          <report-table-card
            title="Key Levels"
            explanation="Initial and current average levels relative to product reference values"
            table-header
            :fields="report.state_cover.header"
            :items="report.state_cover.content"
          />
        </b-col>
        <b-col cols="6">
          <b-card title="Levels Scatter">
            <b-card-sub-title class="pb-2">
              Current levels of properties by product and time to go
            </b-card-sub-title>
            <vue-apex-charts
              type="scatter"
              height="300"
              :options="coverScatterOptions"
              :series="report.coverScatter.data"
            />
          </b-card>
        </b-col>
      </b-row>

      <div class="pagebreak" />

      <b-row>
        <b-col>
          <b-card>
            <div class="d-flex flex-column">
              <b-card-title>Breakdown Options</b-card-title>
              <b-card-sub-title>The four breakdown charts are generated from the product subset selected here</b-card-sub-title>
              <div class="d-flex flex-wrap mt-2 align-items-center">
                <div class="d-flex align-items-center">
                  <span class="mr-1">Product subset</span>
                  <v-select
                    v-model="productSubset.selectedSubset"
                    :options="productSubset.options"
                    :clearable="false"
                    :reduce="option => option.value"
                    label="label"
                    style="min-width: 150px;"
                  />
                </div>
                <div
                  v-if="productSubset.selectedSubset !== 'Live'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="pr-1 mt-1 pl-0 pl-xl-3 mt-xl-0"
                    style="max-width: 300px;"
                  >
                    <span v-if="productSubset.selectedSubset === 'All'">Live products plus those matured in the selected range</span>
                    <span v-if="productSubset.selectedSubset === 'Matured'">Products that matured in the selected range</span>
                  </span>
                  <div class="d-flex align-items-center my-1 my-md-0 justify-content-end">
                    <span class="mr-1">From</span>
                    <b-form-datepicker
                      v-model="productSubset.dateFrom"
                      placeholder="From"
                      style="width: 200px"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :disabled="portfolioId === 'u'"
                      reset-button
                      :reset-value="''"
                      label-reset-button="Clear"
                      offset="160"
                      locale="en-CA"
                      right
                    />
                  </div>
                  <div class="d-flex align-items-center justify-content-end">
                    <span class="mx-1">To</span>
                    <b-form-datepicker
                      v-model="productSubset.dateTo"
                      placeholder="To"
                      style="width: 200px"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :disabled="portfolioId === 'u'"
                      reset-button
                      :reset-value="''"
                      label-reset-button="Clear"
                      offset="160"
                      locale="en-CA"
                      right
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col
          cols="6"
        >
          <b-card title="Underlyings">
            <b-card-sub-title>
              Portfolio breakdown by underlying showing proportion and return
            </b-card-sub-title>
            <b-row class="match-height">
              <b-col cols="7">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.underlyings"
                  :options="chartOptions.bar"
                  :series="report.underlyings.holdingSeries"
                />
              </b-col>
              <b-col cols="5">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.underlyings"
                  :options="returnBarChartOptions"
                  :series="report.underlyings.returnSeries"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          cols="6"
        >
          <b-card title="Issuers">
            <b-card-sub-title>
              Portfolio breakdown by issuer showing proportion and return
            </b-card-sub-title>
            <b-row class="match-height">
              <b-col cols="7">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.issuers"
                  :options="chartOptions.bar"
                  :series="report.issuers.holdingSeries"
                />
              </b-col>
              <b-col cols="5">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.issuers"
                  :options="returnBarChartOptions"
                  :series="report.issuers.returnSeries"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col
          cols="6"
        >
          <b-card title="Product Types">
            <b-card-sub-title>
              Portfolio breakdown by type showing proportion and return
            </b-card-sub-title>
            <b-row class="match-height">
              <b-col cols="7">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.productTypes"
                  :options="chartOptions.bar"
                  :series="report.productTypes.holdingSeries"
                />
              </b-col>
              <b-col cols="5">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.productTypes"
                  :options="returnBarChartOptions"
                  :series="report.productTypes.returnSeries"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          cols="6"
        >
          <b-card title="Classification">
            <b-card-sub-title>
              Portfolio breakdown by classification showing proportion and return
            </b-card-sub-title>
            <b-row class="match-height">
              <b-col cols="7">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.capAim"
                  :options="chartOptions.bar"
                  :series="report.capAim.holdingSeries"
                />
              </b-col>
              <b-col cols="5">
                <vue-apex-charts
                  class="lighten-datalabel"
                  type="bar"
                  :height="chartHeights.capAim"
                  :options="returnBarChartOptions"
                  :series="report.capAim.returnSeries"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <div class="pagebreak" />
    </template>
    <template v-if="portfolioId != 'u'">
      <b-row>
        <b-col>
          <div class="pagebreak" />
          <b-card class="mb-1">
            <div class="d-flex justify-content-between mb-2">
              <b-card-title class="mb-0">
                Portfolio Upcoming Events
              </b-card-title>
              <div
                v-if="false"
                class="d-flex align-items-center"
              >
                <span class="mr-1">Group by Product</span>
                <b-form-checkbox
                  v-model="groupEventsTable"
                  name="check-button"
                  switch
                  inline
                />
              </div>
            </div>
            <div
              v-if="false"
              class="font-weight-bold mb-1"
            >
              {{ allUpcomingPagination.totalRows }} results found <span v-if="allUpcomingPagination.totalRows > allUpcomingPagination.perPage">| Showing results {{ `from ${allUpcomingShowing[0]} to ${allUpcomingShowing[1]}` }}</span>
            </div>
            <b-input-group
              v-if="false"
              class="pb-1 input-group-merge"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="allUpcomingPagination.filter"
                placeholder="Search (ID, type, product, date or event)"
                :disabled="allUpcomingPagination.loading || allUpcomingItemsWithEvents < 1"
                @change="portfolioId === 'v' ? loadVirtualAllUpcoming() : loadAllUpcoming()"
              />
            </b-input-group>

            <div
              v-if="allUpcomingPagination.totalRows > 10"
              class="mb-1"
            >
              <span>Showing the first 10 entries out of {{ allUpcomingPagination.totalRows }}. For the full list of events visit the online version of this report at <a :href="fullReportLink.href">{{ fullReportLink.text }}</a></span>
            </div>

            <b-card
              id="all-upcoming-table"
              no-body
            >

              <b-table
                v-if="!allUpcomingPagination.error"
                responsive
                hover
                :items="allUpcomingItemsWithEvents"
                :fields="allUpcomingFields"
                :sort-by.sync="allUpcomingPagination.sortBy"
                :sort-desc.sync="allUpcomingPagination.sortDesc"
                :sort-direction="allUpcomingPagination.sortDirection"
                :no-local-sorting="true"
                table-class="events-table-base"
                tbody-tr-class="events-table-row"
                @row-clicked="productClicked"
              >
                <template #cell(producttype)="data">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      class="mr-1"
                      :variant="data.item.pticoncolor || 'light-secondary'"
                    >
                      <feather-icon
                        size="18"
                        :icon="data.item.pticonname || 'ArrowRightIcon'"
                      />
                    </b-avatar>
                    <span>{{ data.item.producttypename }}</span>
                  </div>
                </template>
                <template #cell(eventdate)="data">
                  <span v-if="!data.detailsShowing || !groupEventsTable">{{ data.item.eventdate }}</span>
                </template>
                <template #cell(eventname)="data">
                  <div
                    v-if="!data.detailsShowing || !groupEventsTable"
                    class="events-table-grid-container"
                  >
                    <div
                      v-for="(eventName, index) in data.item.eventname"
                      :key="index"
                      :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                    >
                      <span>{{ eventName }}</span>
                    </div>
                  </div>
                </template>
                <template #cell(status)="data">
                  <div
                    v-if="!data.detailsShowing || !groupEventsTable"
                    class="events-table-grid-container"
                  >
                    <div
                      v-for="(status, index) in data.item.status"
                      :key="index"
                      :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                    >
                      <feather-icon
                        v-if="status"
                        :icon="status"
                        :class="`text-${data.item.color[index]} mr-1`"
                        size="18"
                      />
                      <span v-if="data.item.spot[index] && data.item.level[index]">
                        {{ Math.round((data.item.level[index] / data.item.spot[index]) * 100) }}%
                      </span>
                    </div>
                  </div>
                </template>
                <template #cell(probability)="data">
                  <div
                    v-if="!data.detailsShowing || !groupEventsTable"
                    class="events-table-grid-container"
                  >
                    <div
                      v-for="(probability, index) in data.item.probability"
                      :key="index"
                      :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                    >
                      <span v-if="probability || probability === 0">
                        {{ Math.round(parseFloat(probability * 100)) }}%
                      </span>
                    </div>
                  </div>
                </template>

                <template #cell(eventnum)="data">
                  <span v-if="!data.detailsShowing || !groupEventsTable">{{ data.item.eventnum }}</span>
                </template>
                <template #cell(toggle)="data">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    @click.prevent="data.toggleDetails"
                  >
                    <feather-icon :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card
                    class="m-0 p-0"
                    no-body
                  >
                    <div class="d-flex justify-content-center">
                      <b-table
                        class="expanded-events-table"
                        :sticky-header="true"
                        responsive
                        :hover="false"
                        :items="row.item.events"
                        :fields="eventFields"
                        table-class="events-table-base"
                        tbody-tr-class="events-table-row"
                      >
                        <template #cell(eventname)="data">
                          <div class="events-table-grid-container">
                            <div
                              v-for="(eventName, index) in data.item.eventname"
                              :key="index"
                              :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                            >
                              <span>{{ eventName }}</span>
                            </div>
                          </div>
                        </template>
                        <template #cell(status)="data">
                          <div class="events-table-grid-container">
                            <div
                              v-for="(status, index) in data.item.status"
                              :key="index"
                              :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                            >
                              <feather-icon
                                v-if="status"
                                :icon="status"
                                :class="`text-${data.item.color[index]} mr-1`"
                                size="18"
                              />
                              <span v-if="data.item.spot[index] && data.item.level[index]">
                                {{ Math.round((data.item.level[index] / data.item.spot[index]) * 100) }}%
                              </span>
                            </div>
                          </div>
                        </template>
                        <template #cell(probability)="data">
                          <div class="events-table-grid-container">
                            <div
                              v-for="(probability, index) in data.item.probability"
                              :key="index"
                              :class="`events-table-grid-item ${(index + 1 < data.item.eventname.length) ? 'events-table-grid-divider' : ''}`"
                            >
                              <span v-if="probability || probability === 0">
                                {{ Math.round(parseFloat(probability * 100)) }}%
                              </span>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </b-card>
                </template>
              </b-table>
              <error-display
                v-else
                @action-clicked="loadAllUpcoming"
              />

              <div
                v-if="allUpcomingPagination.loading"
                class="text-center py-5 d-flex flex-column justify-content-center align-items-center"
                :style="`height: ${allUpcomingPagination.loadingContainerHeight}`"
              >
                <b-spinner variant="primary" />
              </div>

              <div
                v-if="false"
                class="d-flex justify-content-between px-2"
              >
                <div class="my-2">
                  <span class="pb-1">Rows Per Page</span>
                  <b-form-select
                    v-model="allUpcomingPagination.perPage"
                    :options="allUpcomingPagination.perPageOptions"
                    class="pagination-select"
                  />
                </div>

                <div class="my-2 d-flex flex-column justify-content-end">
                  <b-pagination
                    v-model="allUpcomingPagination.currentPage"
                    :total-rows="allUpcomingPagination.totalRows"
                    :per-page="allUpcomingPagination.perPage"
                    align="center"
                    size="lg"
                    class="mb-0"
                  />
                </div>
              </div>
            </b-card>
          </b-card>
        </b-col>
      </b-row>

      <disclaimer />
    </template>
    <!-- Create Portfolio Modal -->
    <b-modal
      v-if="productIds.length <= maxNumberOfProductsInPortfolio"
      v-model="createPortfolioModal.show"
      centered
      :title="`Create Portfolio Using ${productIds.length} Products`"
      hide-footer
    >
      <create-portfolio-modal-content
        :portfolios="portfolios"
        :product-ids="productIds"
        description="product"
        @portfolio-created="onPortfolioCreated"
      />
    </b-modal>
    <b-modal
      v-else
      v-model="createPortfolioModal.show"
      ok-only
      ok-variant="danger"
      ok-title="Close"
      modal-class="modal-danger"
      centered
      title="Product Per Portfolio Limit Reached"
    >
      <b-card-text>
        You can only include {{ maxNumberOfProductsInPortfolio }} products in one portfolio. Try to narrow your search
        to get under the limit.
      </b-card-text>
    </b-modal>

    <!-- Product List Modal -->
    <b-modal
      v-model="productListModal.show"
      title="Products"
      size="lg"
      scrollable
      hide-footer
    >
      <div>
        <b-input-group class="my-2 input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="productListModal.filter"
            placeholder="Search (ID or name)"
            :disabled="loading"
          />
        </b-input-group>
        <b-table
          :items="productListModalData"
          :fields="productListModal.fields"
          :per-page="productListModal.perPage"
          :current-page="productListModal.currentPage"
          responsive
          hover
          @row-clicked="onProductClicked"
        />
        <div
          v-if="productListModalData.length > 5"
          class="py-2 d-flex justify-content-between"
        >
          <div class="my-2">
            <span class="pb-1">Rows Per Page</span>
            <b-form-select
              v-model="productListModal.perPage"
              :options="productListModal.perPageOption"
              class="pagination-select"
            />
          </div>

          <div class="my-2 d-flex flex-column justify-content-end">
            <b-pagination
              v-model="productListModal.currentPage"
              :total-rows="productListModalData.length"
              :per-page="productListModal.perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Print Ready Indicator for Puppeteer -->
    <template v-if="printReady">
      <span class="pdf--print--ready" />
    </template>
  </div>
</template>

<script>
import { $themeColors, $themeConfig } from '@themeConfig'
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BTable,
  BAvatar,
  BFormCheckbox,
  BFormSelect,
  BFormDatepicker,
  BPagination,
  BSpinner,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BLink,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import LoadingContainer from '@/views/components/LoadingContainer.vue'
import CreatePortfolioModalContent from '@/views/components/CreatePortfolioModalContent.vue'
import dayjs from 'dayjs'
import _ from 'lodash'
import EcommerceOrderChart from '@/views/ecommerce/EcommerceOrderChart.vue'
import HistogramChart from '@/views/components/HistogramChart.vue'
import ReportTableCard from '@/views/components/ReportTableCard.vue'
import ErrorDisplay from '@/views/components/ErrorDisplay.vue'
import ReportPrintBrandHeader from '@/views/print/ReportPrintBrandHeader.vue'
import Disclaimer from '@/views/components/Disclaimer.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ReportPrintBrandHeader,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BTable,
    BAvatar,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    BPagination,
    BSpinner,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BLink,
    VueApexCharts,
    EcommerceOrderChart,
    HistogramChart,
    ReportTableCard,
    LoadingContainer,
    CreatePortfolioModalContent,
    ErrorDisplay,
    Disclaimer,
    vSelect,
  },
  props: {
    portfolioId: {
      type: [Number, String],
      required: true,
    },
    searchFilter: {
      type: [Object],
      required: false,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      initialLoad: true,
      loading: false,
      reportError: null,
      report: {
        portfolioName: '',
        state_summary: {
          header: [
            { key: 'field', label: 'Category' },
            { key: 'value', label: 'Number' },
          ],
          content: [],
        },
        state_maturity: {
          header: [],
          content: [],
        },
        state_times: {
          header: [],
          content: [],
        },
        state_cover: {
          header: [],
          content: [],
        },
        futureMaturities: {
          categories: [],
          series: [
            {
              name: 'Maturities',
              data: [],
            },
          ],
        },
        returns: [{
          data: [
          ],
        }],

        underlyings: {
          holdingSeries: [],
          returnSeries: [],
        },
        issuers: {
          holdingSeries: [],
          returnSeries: [],
        },
        productTypes: {
          holdingSeries: [],
          returnSeries: [],
        },
        capAim: {
          holdingSeries: [],
          returnSeries: [],
        },
        payoffScatter: {
          data: [],
        },
        coverScatter: {
          data: [],
        },
        portfolioEvolutionSeries: [],
        portfolioEvolutionIndexSeries: [],
        indexUnderlyingVolRatio: '',
      },
      chartHeights: {
        underlyings: 400,
        issuers: 400,
        productTypes: 250,
        capAim: 250,
      },
      chartOptions: {
        payoffScatter: {
          chart: {
            animations:
              {
                enabled: false,
              },
            toolbar: {
              show: false,
            },
          },
          colors: [$themeColors.primary, $themeColors.success, $themeColors.danger,
            $themeColors.secondary, $themeColors.info, $themeColors.warning],
          markers: {
            size: 4,
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          xaxis: {
            title: {
              text: 'Relative time of maturity',
            },
            tickAmount: 10,
            labels: {
              formatter(val) {
                return parseFloat(val).toFixed(2)
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            title: {
              text: 'Payoff value',
            },
            tickAmount: 7,
          },
        },
        coverScatter: {
          chart: {
            animations:
              {
                enabled: false,
              },
            toolbar: {
              show: false,
            },
          },
          colors: [$themeColors.primary, $themeColors.success, $themeColors.danger,
            $themeColors.secondary, $themeColors.info, $themeColors.warning],
          markers: {
            size: 4,
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          xaxis: {
            title: {
              text: 'Relative time of maturity',
            },
            tickAmount: 10,
            labels: {
              formatter(val) {
                return parseFloat(val).toFixed(2)
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            title: {
              text: 'Current level',
            },
            tickAmount: 7,
          },
        },
        bar: {
          chart: {
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          xaxis: {
            title: {
              text: 'Proportion',
            },
          },
        },
        area: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          colors: [$themeColors.danger, $themeColors.warning, $themeColors.success],
          fill: {
            gradient: {
              shadeIntensity: 0,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              // set to false to remove horizontal lines through graph
              lines: {
                show: true,
              },
            },
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            labels: {},
          },
          stroke: {
            width: 3,
            curve: 'smooth',
          },
          theme: {},
          tooltip: {
            x: {
              formatter(val) {
                return dayjs(val).format('YYYY-MM-DD')
              },
            },
            shared: false,
            marker: false,
          },
          xaxis: {
            type: 'datetime',
            axisTicks: {
              show: true,
            },
            tooltip: {
              enabled: true,
              formatter(val) {
                return dayjs(val).format('YYYY-MM-DD')
              },
            },
            labels: {
              show: true,
              showDuplicates: false,
              formatter(val) {
                return dayjs(val).format('YYYY-MM-DD')
              },
            },
          },
          yaxis: [
            {
              title: {
                text: 'Value',
              },
              axisBorder: {
                show: true,
              },
              axisTicks: {
                show: true,
              },
              labels: {
                formatter(val) {
                  return parseFloat(val).toFixed(2)
                },
              },
              opposite: false,
            },
          ],
        },
        evolutionLine: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          colors: [$themeColors.warning, $themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.info],
          stroke: {
            // width: [3, 3],
            width: 3,
            curve: 'smooth',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          tooltip: {
            x: {
              formatter(val) {
                return dayjs(val).format('YYYY-MM-DD')
              },
            },
            shared: false,
            marker: false,
          },
          xaxis: {
            type: 'datetime',
            axisTicks: {
              show: true,
            },
            tooltip: {
              enabled: true,
              formatter(val) {
                return dayjs(val).format('YYYY-MM-DD')
              },
            },
            labels: {
              show: true,
              showDuplicates: false,
              formatter(val) {
                return dayjs(val).format('YYYY-MM-DD')
              },
            },
          },
          // yaxis options are set in evolutionLineChartOptions computed prop
        },
      },
      clickedProductId: '',
      products: [],
      allupcoming: {
        fields: [
          /*   { key: 'row', label: 'Row', thStyle: { width: '5%' } }, */
          {
            key: 'productid', label: 'Id', thStyle: { width: '5%' }, sortable: true,
          },
          {
            key: 'producttype', label: 'Type', thStyle: { width: '10%' }, sortable: true,
          },
          {
            key: 'productname', label: 'Product', thStyle: { width: '33%' }, sortable: true,
          },
          {
            key: 'eventdate', label: 'Date', thStyle: { width: '12%' }, sortable: true,
          },
          {
            key: 'eventname', label: 'Event', thStyle: { width: '22%' }, tdClass: 'p-0',
          },
          {
            key: 'status', label: 'Level', thStyle: { width: '12%' }, tdClass: 'p-0',
          },
          {
            key: 'probability', label: 'Probability', thStyle: { width: '8%' }, tdClass: 'p-0',
          },
          /* { key: 'eventnum', label: 'Position', thStyle: { width: '6%' } },
          { key: 'finalmarketdate', label: 'Final Date', thStyle: { width: '12%' } }, */
          /*
          { key: 'valuation', label: 'Valuation', thStyle: { width: '8%' } },
          */
        ],
        items: [],
      },
      eventFields: [
        {
          key: 'eventnum', label: 'Position', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'eventdate', label: 'Date', thStyle: { width: '20%' }, sortable: true,
        },
        {
          key: 'eventname', label: 'Event', thStyle: { width: '45%' }, tdClass: 'p-0',
        },
        {
          key: 'status', label: 'Level', thStyle: { width: '17%' }, tdClass: 'p-0',
        },
        {
          key: 'probability', label: 'Probability', thStyle: { width: '13%' }, tdClass: 'p-0',
        },
      ],
      selectedEvolution: 'dynamic',
      evolutionOptions: [
        { text: 'Index', value: 'dynamic' },
        { text: 'Buy-and-hold', value: 'equal' },
      ],
      allUpcomingPagination: {
        currentPage: 1,
        perPage: 10,
        previousPage: null,
        nextPage: null,
        totalPages: 0,
        totalRows: 0,
        perPageOptions: [5, 10, 20, 50, 100, 500],
        loading: false,
        error: false,
        loadingContainerHeight: '200px',
        filter: '',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
      },
      createPortfolioModal: {
        show: false,
      },
      productListModal: {
        show: false,
        products: [],
        fields: [
          {
            key: 'productId', label: 'ID', thStyle: { width: '5%' }, sortable: true,
          },
          {
            key: 'productName', label: 'Product', thStyle: { width: '80%' }, sortable: true,
          },
        ],
        perPage: 5,
        currentPage: 1,
        perPageOption: [3, 5, 10, 25, 50, 100],
        filter: '',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
      },
      groupEventsTable: false,
      virtualPortfolio: {
        lastLoadedSearchFilter: null,
        lastLoadCached: false,
      },
      printReady: false,
      reportLoaded: false,
      allUpcomingLoaded: false,
      breakdownLoaded: false,

      // Product Weight Mode
      productWeightMode: {
        allowedMode: [],
        allowedModeName: [],
        selectedMode: 'default',
        selectedModeIndex: 'default',
      },

      // Product Subset | Breakdown Constraint
      productSubset: {
        options: [
          { label: 'All', value: 'All' },
          { label: 'Matured', value: 'Matured' },
          { label: 'Live', value: 'Live' },
        ],
        selectedSubset: 'All',
        dateFrom: 'default',
        dateTo: 'default',
      },
      breakdownLoading: false,
      breakdownError: false,
    }
  },
  computed: {
    date() {
      return dayjs().format('YYYY-MM-DD HH:mm')
    },
    appName() {
      return $themeConfig.app.appName
    },
    appBrand() {
      return $themeConfig.app.brand
    },
    portfolioTitle() {
      if (this.portfolioId === 'v') {
        return 'Hypothetical portfolio based on product search results'
      }
      if (this.portfolioId === 'u') {
        return 'Universe Overview'
      }
      return `Portfolio ${this.portfolioId}: ${this.report.portfolioName}`
    },
    buyHoldLineChartOptions() {
      const options = { ...this.chartOptions.area }
      options.xaxis = {
        type: 'datetime',
        xTickAmount: 5,
        axisTicks: {
          show: true,
        },
        labels: {
          formatter(val, timestamp) {
            return dayjs(timestamp).format('YYYY-MM-DD')
          },
        },
      }
      return options
    },
    evolutionLineChartOptions() {
      const options = { ...this.chartOptions.evolutionLine }
      if (this.portfolioId === 'u') {
        options.yaxis = {
          title: {
            text: 'Value',
          },
          decimalsInFloat: 2,
        }
      } else {
        options.yaxis = [
          {
            title: {
              text: 'Value',
            },
            decimalsInFloat: 2,
          }, {
            opposite: true,
            title: {
              text: 'Live',
            },
            decimalsInFloat: 0,
          // max: 100,
          // forceNiceScale: true,
          },
          {
            labels: {
              show: false,
            },
            seriesName: 'Portfolio value',
            decimalsInFloat: 2,
          },
          {
            labels: {
              show: false,
            },
            seriesName: 'Portfolio value',
            decimalsInFloat: 2,
          },
        ]
      }
      return options
    },
    holdingBarChartOptions() {
      return { ...this.chartOptions.bar }
    },
    returnBarChartOptions() {
      const options = { ...this.chartOptions.bar }
      options.colors = [$themeColors.warning]
      options.xaxis = { title: { text: 'Return' } }
      options.yaxis = { labels: { show: false } }
      return options
    },
    payoffScatterOptions() {
      const options = { ...this.chartOptions.payoffScatter }
      options.xaxis.min = this.report.payoffScatter.options.xmin
      options.xaxis.max = this.report.payoffScatter.options.xmax
      options.xaxis.tickAmount = this.report.payoffScatter.options.xTickAmount
      options.yaxis.min = this.report.payoffScatter.options.ymin
      options.yaxis.max = this.report.payoffScatter.options.ymax
      options.yaxis.tickAmount = this.report.payoffScatter.options.yTickAmount
      options.annotations = this.report.payoffScatter.options.annotations
      if (this.portfolioId !== 'u') {
        options.chart.events = {
          markerClick: (event, chartContext, { seriesIndex, dataPointIndex /* , config */ }) => {
            const productId = this.report.payoffScatter.products[seriesIndex].data[dataPointIndex]

            if (event.ctrlKey || event.button === 1) {
              const route = this.$router.resolve({ name: 'product-report', params: { productId } })
              window.open(route.href, '_blank')
            } else {
              this.$router.push({ name: 'product-report', params: { productId } })
            }
          },
          dataPointMouseEnter: event => {
          // eslint-disable-next-line no-param-reassign
            event.path[0].style.cursor = 'pointer'
          },
        }
        options.tooltip = {
          x: {
            formatter: (value, {
              seriesIndex, dataPointIndex,
            }) => {
              const productId = this.report.payoffScatter.products[seriesIndex].data[dataPointIndex]

              // Find the product from products
              const product = this.products.find(pd => pd.productId === productId)

              return `${product.productName} [${value}]`
            },
          },
        }
      }

      if (this.portfolioId === 'u') {
        options.tooltip = {
          enabled: false,
        }
      }
      return options
    },
    coverScatterOptions() {
      const options = { ...this.chartOptions.coverScatter }
      options.xaxis.min = this.report.coverScatter.options.xmin
      options.xaxis.max = this.report.coverScatter.options.xmax
      options.xaxis.tickAmount = this.report.coverScatter.options.xTickAmount
      options.yaxis.min = this.report.coverScatter.options.ymin
      options.yaxis.max = this.report.coverScatter.options.ymax
      options.yaxis.tickAmount = this.report.coverScatter.options.yTickAmount
      options.annotations = this.report.coverScatter.options.annotations
      if (this.portfolioId !== 'u') {
        options.chart.events = {
          markerClick: (event, chartContext, { seriesIndex, dataPointIndex }) => {
            const productId = this.report.coverScatter.data[seriesIndex].products[dataPointIndex]

            if (event.ctrlKey || event.button === 1) {
              const route = this.$router.resolve({ name: 'product-report', params: { productId } })
              window.open(route.href, '_blank')
            } else {
              this.$router.push({ name: 'product-report', params: { productId } })
            }
          },
          dataPointMouseEnter: event => {
          // eslint-disable-next-line no-param-reassign
            event.path[0].style.cursor = 'pointer'
          },
        }
        options.tooltip = {
          x: {
            formatter: (value, {
              seriesIndex, dataPointIndex,
            }) => {
              const productId = this.report.coverScatter.data[seriesIndex].products[dataPointIndex]

              // Find the product from products
              const product = this.products.find(pd => pd.productId === productId)

              return `${product.productName} [${value}]`
            },
          },
        }
      }

      if (this.portfolioId === 'u') {
        options.tooltip = {
          enabled: false,
        }
      }

      return options
    },
    productIds() {
      // Check if the report is for hypothetical portfolio
      if (this.portfolioId !== 'v') {
        return []
      }

      return this.products.map(p => p.productId)
    },
    maxNumberOfProductsInPortfolio() {
      return localStorage.getItem('maxNumberOfProductsInPortfolio') || this.$maxPortfolioSize
    },
    portfolios() {
      return this.$store.state.app.portfolios
    },
    getRedirectSource() {
      if (this.$route.query.src) {
        switch (this.$route.query.src) {
          case 'data-underlyings':
            return {
              name: 'Underlyings',
              route: this.$route.query.src,
            }
          case 'data-issuers':
            return {
              name: 'Issuers',
              route: this.$route.query.src,
            }
          default:
            return {
              name: 'Products',
              route: this.$route.query.src,
            }
        }
      } else {
        return {
          name: 'Products',
          route: 'products',
        }
      }
    },
    allUpcomingItemsWithEvents() {
      const { items } = this.allupcoming
      const filteredItems = []

      // Loop through the items to check for events property
      items.forEach(i => {
        let eventWithDetail = i
        if (Object.prototype.hasOwnProperty.call(i, 'events') && i.events.length > 0) {
          eventWithDetail = {
            ...eventWithDetail,
            ...i.events[0],
          }
        }
        filteredItems.push(eventWithDetail)
      })

      return filteredItems
    },
    allUpcomingFields() {
      const fields = this.allupcoming.fields.slice()
      if (this.groupEventsTable) {
        fields.unshift(
          { key: 'toggle', label: 'Show All Events', thStyle: { width: '5%' } },
        )
      }
      return fields
    },
    allUpcomingShowing() {
      const start = (this.allUpcomingPagination.perPage * this.allUpcomingPagination.currentPage) - this.allUpcomingPagination.perPage + 1
      const end = (this.allUpcomingPagination.perPage * this.allUpcomingPagination.currentPage) > this.allUpcomingPagination.totalRows ? this.allUpcomingPagination.totalRows : (this.allUpcomingPagination.perPage * this.allUpcomingPagination.currentPage)
      return [start, end]
    },
    productListModalData() {
      if (this.productListModal.filter && this.productListModal.filter.length > 0) {
        return this.productListModal.products.filter(item => (item.productName.toLowerCase().search(this.productListModal.filter.toLowerCase()) !== -1)
          || (item.productId.toString().search(this.productListModal.filter.toLowerCase()) !== -1))
      }
      return this.productListModal.products
    },
    cachedFilter() {
      let cachedFilter = localStorage.getItem('searchFilter')
      cachedFilter = cachedFilter && JSON.parse(cachedFilter)
      const expiresOn = localStorage.getItem('searchFilterExpires')

      if (!cachedFilter || !expiresOn || expiresOn < Date.now()) {
        return null
      }

      return cachedFilter
    },
    fullReportLink() {
      const link = {
        href: `${process.env.BASE_URL}portfolios/report/${this.portfolioId}`,
        text: `${window.location.host}${process.env.BASE_URL}portfolios/report/${this.portfolioId}`,
      }
      return link
    },
    breakdownConstraints() {
      return {
        dateFrom: this.productSubset.dateFrom,
        dateTo: this.productSubset.dateTo,
        showSet: this.productSubset.selectedSubset,
      }
    },
    productWeightModeOptions() { // Base options based on allowedMode and allowedModeName
      const selectOptions = []
      this.productWeightMode.allowedMode.forEach((mode, index) => {
        selectOptions.push({ name: this.productWeightMode.allowedModeName[index], value: mode })
      })
      return selectOptions
    },
    indexWeightMode() {
      if (this.portfolioId !== 'u' && this.productWeightMode.selectedMode !== this.productWeightMode.selectedModeIndex) {
        const idx = this.productWeightMode.allowedMode.findIndex(e => e === this.productWeightMode.selectedModeIndex)
        if (idx > -1) {
          return this.productWeightMode.allowedModeName[idx]
        }
      }
      return ''
    },
  },
  watch: {
    'allUpcomingPagination.currentPage': function () {
      if (this.portfolioId === 'v') {
        this.loadVirtualAllUpcoming()
      } else {
        this.loadAllUpcoming()
      }
    },
    'allUpcomingPagination.perPage': function () {
      if (this.allUpcomingPagination.currentPage === 1) {
        if (this.portfolioId === 'v') {
          this.loadVirtualAllUpcoming()
        } else {
          this.loadAllUpcoming()
        }
      } else {
        this.allUpcomingPagination.currentPage = 1
      }
    },
    'allUpcomingPagination.sortBy': function () {
      if (this.portfolioId === 'v') {
        this.loadVirtualAllUpcoming()
      } else {
        this.loadAllUpcoming()
      }
    },
    'allUpcomingPagination.sortDesc': function () {
      if (this.portfolioId === 'v') {
        this.loadVirtualAllUpcoming()
      } else {
        this.loadAllUpcoming()
      }
    },
    groupEventsTable() {
      if (this.portfolioId === 'v') {
        this.loadVirtualAllUpcoming()
      } else {
        this.loadAllUpcoming()
      }
    },
    selectedEvolution() {
      // Re-render evolution charts
      if (this.selectedEvolution === 'dynamic') {
        this.$refs.dynamicChart.init()
      } else if (this.selectedEvolution === 'equal') {
        this.$refs.equalChart.init()
      }
    },
    reportLoaded() {
      if (this.reportLoaded && this.allUpcomingLoaded && this.breakdownLoaded) {
        this.printReport()
      }
    },
    allUpcomingLoaded() {
      if (this.reportLoaded && this.allUpcomingLoaded && this.breakdownLoaded) {
        this.printReport()
      }
    },
    breakdownLoaded() {
      if (this.reportLoaded && this.allUpcomingLoaded && this.breakdownLoaded) {
        this.printReport()
      }
    },
  },
  mounted() {
    // Setup for print
    // Increase browser window zoom
    document.body.style.zoom = '115%'

    this.setThemeToLight()

    if (this.initialLoad) {
      this.loadReport()
      this.initialLoad = false
    } else if (this.portfolioId === 'v') {
      if (this.virtualPortfolio.lastLoadedSearchFilter) {
        if (this.virtualPortfolio.lastLoadCached && !this.searchFilter) {
          if (_.isEqual(this.virtualPortfolio.lastLoadedSearchFilter, this.cachedFilter)) {
            return
          }
          this.loadReport()
          return
        }
        if (_.isEqual(this.virtualPortfolio.lastLoadedSearchFilter, this.searchFilter)) {
          return
        }
        if (!this.searchFilter && !this.virtualPortfolio.lastLoadCached) {
          return
        }
        this.loadReport()
      }
    } else if (this.$store.state.app.updatedPortfolios.includes(parseInt(this.portfolioId, 10))) {
      this.virtualPortfolio.lastLoadedSearchFilter = null
      this.loadReport()
    }

    this.$store.commit('app/REMOVE_UPDATED_PORTFOLIO', this.portfolioId)
  },
  methods: {
    productClicked(item, index, event) {
      if (!item.productid) return
      this.clickedProductId = item.productid
      if (event.ctrlKey || event.button === 1) {
        const route = this.$router.resolve({ name: 'product-report', params: { productId: this.clickedProductId } })
        window.open(route.href, '_blank')
      } else {
        this.$router.push({ name: 'product-report', params: { productId: this.clickedProductId } })
      }
    },
    fillReport(data) {
      this.report.portfolioName = data.portfoliosummary.portfolioname

      this.report.state_summary.content = data.portfoliosummary.state_summary
      // Check if there is Start of portfolio
      const searchResult = this.report.state_summary.content.findIndex(rep => rep.field === 'Start of portfolio')
      if (searchResult !== -1) {
        this.report.state_summary.content[searchResult].value = dayjs(this.report.state_summary.content[searchResult].value).format('YYYY-MM-DD')
      }
      this.report.returns = data.portfoliosummary.returns
      this.report.futureMaturities = data.portfoliosummary.futurematurities

      this.report.state_maturity = data.portfoliosummary.state_maturity
      this.report.state_times = data.portfoliosummary.state_times
      this.report.state_cover = data.portfoliosummary.state_cover

      this.report.payoffScatter = data.portfoliosummary.state_payoffscatter
      this.report.coverScatter = data.portfoliosummary.state_coverscatter
      this.report.portfolioEvolutionSeries = data.portfoliosummary.portfoliochart.SHOWDATA.DATA
      this.report.portfolioEvolutionIndexSeries = this.portfolioId === 'u' ? [] : data.portfoliosummary.indexchart.SHOWDATA.DATA
      this.report.indexUnderlyingVolRatio = this.portfolioId === 'u' ? '' : data.portfoliosummary.indexchart.SHOWDATA.INDEXUNDERLYINGVOLRATIO

      this.products = data.portfoliosummary.products

      // Product Weight Mode
      this.productWeightMode.allowedMode = data.portfoliosummary.allowedmode
      this.productWeightMode.allowedModeName = data.portfoliosummary.allowedmodename
      this.productWeightMode.selectedMode = data.portfoliosummary.selectedmode
      this.productWeightMode.selectedModeIndex = data.portfoliosummary.selectedmodeindex

      this.reportLoaded = true
    },
    fillBreakdown(data) {
      this.report.underlyings.holdingSeries = data.portfoliosummary.underlyings.holdingseries
      this.report.underlyings.returnSeries = data.portfoliosummary.underlyings.returnseries

      this.report.issuers.holdingSeries = data.portfoliosummary.issuers.holdingseries
      this.report.issuers.returnSeries = data.portfoliosummary.issuers.returnseries

      this.report.productTypes.holdingSeries = data.portfoliosummary.producttypes.holdingseries
      this.report.productTypes.returnSeries = data.portfoliosummary.producttypes.returnseries

      this.report.capAim.holdingSeries = data.portfoliosummary.capaim.holdingseries
      this.report.capAim.returnSeries = data.portfoliosummary.capaim.returnseries

      this.productSubset.dateFrom = data.portfoliosummary.datefrom
      this.productSubset.dateTo = data.portfoliosummary.dateto

      this.updateBarChartHeights()

      this.breakdownLoaded = true
    },
    updateBarChartHeights() {
      const numUnderlyings = this.report.underlyings.holdingSeries[0].data.length
      if (numUnderlyings < 4) {
        this.chartHeights.underlyings = 70 + 80 * numUnderlyings
      } else {
        this.chartHeights.underlyings = 400
      }

      const numIssuers = this.report.issuers.holdingSeries[0].data.length
      if (numIssuers < 4) {
        this.chartHeights.issuers = 70 + 80 * numIssuers
      } else {
        this.chartHeights.issuers = 400
      }

      const numProductTypes = this.report.productTypes.holdingSeries[0].data.length
      if (numProductTypes === 1) {
        this.chartHeights.productTypes = 150
      } else {
        this.chartHeights.productTypes = 250
      }

      const numCapAim = this.report.capAim.holdingSeries[0].data.length
      if (numCapAim === 1) {
        this.chartHeights.capAim = 150
      } else {
        this.chartHeights.capAim = 250
      }
    },
    loadVirtualAllUpcoming() {
      // Get height of product table card
      const tableHeight = document.getElementById('all-upcoming-table')
      const height = tableHeight ? tableHeight.offsetHeight : 0

      this.allUpcomingPagination.loading = true
      this.allUpcomingPagination.error = null

      this.allUpcomingPagination.loadingContainerHeight = height ? `${(height - 172)}px` : '300px'
      this.allupcoming.items = []

      this.$http
        .get('report.cfc?method=getVirtualPortfolioAllUpcoming', {
          params: {
            searchfilter: this.searchFilter || this.cachedFilter,
            page: this.allUpcomingPagination.currentPage,
            perpage: this.allUpcomingPagination.perPage,
            search: this.allUpcomingPagination.filter,
            grouped: this.groupEventsTable,
            sortBy: this.allUpcomingPagination.sortBy,
            sortDesc: this.allUpcomingPagination.sortDesc,
          },
        }).then(response => {
          // Fill the data
          this.allupcoming.items = response.data.DATA

          // Update pagination data
          this.allUpcomingPagination.previousPage = response.data.INFO.PREVIOUSPAGE
          this.allUpcomingPagination.nextPage = response.data.INFO.NEXTPAGE
          this.allUpcomingPagination.currentPage = response.data.INFO.CURRENTPAGE * 1
          this.allUpcomingPagination.totalPages = response.data.INFO.NUMPAGES
          this.allUpcomingPagination.perPage = response.data.INFO.PERPAGE
          this.allUpcomingPagination.totalRows = response.data.INFO.NUMROWS
        })
        .catch(error => {
          this.allUpcomingPagination.error = true
          console.log(error)
        })
        .then(() => {
          this.allUpcomingPagination.loading = false
        })
    },
    loadAllUpcoming() {
      // Get height of product table card
      const tableHeight = document.getElementById('all-upcoming-table')
      const height = tableHeight ? tableHeight.offsetHeight : 0

      this.allUpcomingPagination.loading = true
      this.allUpcomingPagination.error = null

      this.allUpcomingPagination.loadingContainerHeight = height ? `${(height - 172)}px` : '300px'
      this.allupcoming.items = []

      this.$http
        .get('report.cfc?method=getUserPortfolioAllUpcoming', {
          params: {
            portfolioid: this.portfolioId,
            page: this.allUpcomingPagination.currentPage,
            perpage: this.allUpcomingPagination.perPage,
            search: this.allUpcomingPagination.filter,
            sortBy: this.allUpcomingPagination.sortBy,
            sortDesc: this.allUpcomingPagination.sortDesc,
            grouped: this.groupEventsTable,
          },
        }).then(response => {
          console.log(response.data)

          // Fill the data
          this.allupcoming.items = response.data.DATA

          console.log(response.data.info)

          // Update pagination data
          this.allUpcomingPagination.previousPage = response.data.INFO.PREVIOUSPAGE
          this.allUpcomingPagination.nextPage = response.data.INFO.NEXTPAGE
          this.allUpcomingPagination.currentPage = response.data.INFO.CURRENTPAGE * 1
          this.allUpcomingPagination.totalPages = response.data.INFO.NUMPAGES
          this.allUpcomingPagination.perPage = response.data.INFO.PERPAGE
          this.allUpcomingPagination.totalRows = response.data.INFO.NUMROWS

          // Set loaded flag
          this.allUpcomingLoaded = true
        })
        .catch(error => {
          this.allUpcomingPagination.error = true
          console.log(error)
        })
        .then(() => {
          this.allUpcomingPagination.loading = false
        })
    },
    onPortfolioCreated(portfolio) {
      // Show success toast
      this.$bvToast.toast('Portfolio Created', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })

      // Hide the modal
      this.createPortfolioModal.show = false

      // Update store value
      this.$store.dispatch('app/updatePortfolios')
      this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)

      // Redirect to newly created portfolio page
      this.$router.push({ name: 'portfolio-report', params: { portfolioId: portfolio.portfolioId } })
    },
    loadReport() {
      this.loading = true

      // Reset error flag
      this.reportError = null

      if (this.portfolioId === 'v') {
        // Check if the searchFilter prop exists
        if (!this.searchFilter && !this.cachedFilter) {
          return
        }

        // Cache the searchFilter
        if (this.searchFilter) {
          localStorage.setItem('searchFilter', JSON.stringify(this.searchFilter))
          localStorage.setItem('searchFilterExpires', Date.now() + 600000) // Cache expires after 10 minutes
        }

        this.virtualPortfolio.lastLoadedSearchFilter = this.searchFilter || this.cachedFilter
        this.virtualPortfolio.lastLoadCached = !this.searchFilter

        this.$http
          .get('report.cfc?method=getVirtualPortfolioReport', {
            params: {
              searchFilter: this.searchFilter || this.cachedFilter,
            },
          })
          .then(response => { this.fillReport(response.data) })
          .catch(error => {
            console.log('Error Loading Virtual Report: ', error)
            this.reportError = true
          })
          .then(() => { this.loading = false })

        // Reset search filter and sort order
        this.allUpcomingPagination.filter = ''
        this.allUpcomingPagination.sortBy = ''
        this.allUpcomingPagination.sortDesc = false

        this.loadVirtualAllUpcoming()
      } else if (this.portfolioId === 'u') {
        this.$http
          .get('report.cfc?method=getUniversePortfolioReport')
          .then(response => { this.fillReport(response.data) })
          .catch(error => {
            console.log('Error Loading Universe Report: ', error)
            this.reportError = true
          })
          .then(() => { this.loading = false })
      } else {
        this.$http
          .get('report.cfc?method=getUserPortfolioReport', {
            params: {
              portfolioid: this.portfolioId,
              productweightingmode: this.productWeightMode.selectedMode,
            },
          })
          .then(response => { this.fillReport(response.data) })
          .catch(error => {
            console.log('Error Loading Report: ', error)
            this.reportError = true
          })
          .then(() => { this.loading = false })

        this.loadBreakdown()
        this.loadAllUpcoming()
      }
    },
    loadBreakdown() {
      this.$http
        .get('report.cfc?method=getUserPortfolioReportBreakdown', {
          params: {
            portfolioid: this.portfolioId,
            productweightingmode: this.productWeightMode.selectedMode,
            constraints: this.breakdownConstraints,
          },
        }).then(response => { this.fillBreakdown(response.data) })
        .catch(() => { this.breakdownError = true })
        .finally(() => { this.breakdownLoading = false })
    },
    showProductsListModal(productIds) {
      // Clear previous search filter
      this.productListModal.filter = ''

      // Find the products from products array
      this.productListModal.products = this.products.filter(pd => productIds.includes(pd.productId))

      // Reset pagination data
      this.productListModal.currentPage = 1
      this.productListModal.perPage = 5

      // Show the modal
      this.productListModal.show = true
    },
    onProductClicked(item, index, event) {
      // Hide the modal
      this.productListModal.show = false

      if (event.ctrlKey || event.button === 1) {
        const route = this.$router.resolve({ name: 'product-report', params: { productId: item.productId } })
        window.open(route.href, '_blank')
      } else {
        this.$router.push({ name: 'product-report', params: { productId: item.productId } })
      }
    },
    setThemeToLight() {
      this.$store.commit('appConfig/UPDATE_SKIN', 'light')
    },
    printReport() {
      setTimeout(() => {
        this.printReady = true
      }, 1500)
    },
  },
}
</script>

<style lang="scss">
.b-table-details > td {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

<style scoped lang="scss">
.expanded-events-table {
  max-height: 300px;
  max-width: 900px;
  overflow-y: auto;
  scroll-padding: 100px 100px 100px 100px;
}

.expanded-events-table::-webkit-scrollbar {
  width: 4px;
  border-radius: 6px;
}

.expanded-events-table::-webkit-scrollbar-track {
  background: rgba(56, 56, 56, 0.49);
  border-radius: 6px;
}

.expanded-events-table::-webkit-scrollbar-thumb {
  background: rgba(115, 103, 240, 0.85);
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
