<template>
  <b-card
    v-if="series"
    :title="title"
  >
    <b-card-sub-title v-if="explanation">
      {{ explanation }}
    </b-card-sub-title>
    <loading-container v-if="loading" />
    <no-data-container v-else-if="!series || series.length < 1" />
    <b-card-text
      v-else
    >
      <vue-apex-charts
        class="lighten-datalabel"
        type="bar"
        height="340"
        :options="chartOptions"
        :series="series"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { $themeColors } from '@themeConfig'
import { BCard, BCardSubTitle, BCardText } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import LoadingContainer from './LoadingContainer.vue'
import NoDataContainer from './NoDataContainer.vue'

export default {
  components: {
    BCard,
    BCardSubTitle,
    BCardText,
    VueApexCharts,
    LoadingContainer,
    NoDataContainer,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    explanation: {
      type: String,
      default: '',
    },
    series: {
      type: Array,
      required: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            enabled: this.animate,
          },
        },
        colors: [$themeColors.info],
        xaxis: {
          title: {
            text: 'Return (p.a.)',
          },
        },
        yaxis: {
          title: {
            text: 'Frequency',
          },
          labels: {
            formatter(val) {
              return parseFloat(val).toFixed(0)
            },
          },
        },
      },
    }
  },
}
</script>
